import { type Model } from "@/features/types/clientTypes";

export type SetupSkillDto = Model<"Skills.Models.SkillDto">;
export type SetupDto = Model<"Setup.Queries.GetSetupQueryDto">;

export type SetupTenantModel = Model<"Setup.Commands.SetupTenantCommand">;
export type SetupAdvancedRecommendationsModel = Model<"Setup.Commands.SetupAdvancedRecommendationsCommand">;
export type SetupUserCommand = Model<"Setup.Commands.SetupUserCommand">;

export type CompanySize = Model<"Setup.Commands.SetupTenantCommand">["companySize"];

export interface HRISIntegration {
  name: string;
  slug: string;
  squareImage: string;
}

export enum SetupQueryKeys {
  HRIS_INTEGRATIONS = "hris-integrations",
  SETUP = "setup",
  SETUP_TENANT = "setup/tenant",
  SETUP_USER = "setup/user",
  SKILLS_SEARCH = "skills-search",
  EMPLOYEE_SKILLS = "employee-skills"
}

export type SetupTenantDto = Model<"Setup.Queries.GetTenantSetupQueryDto">;
export type SetupUserDto = Model<"Setup.Queries.GetUserSetupQueryDto">;

export type GetEmployeeSkillsDto = Model<"Setup.Queries.GetEmployeeSkillsDto">;

export type SetupType = Model<"Users.SetupType">;

export type CompleteSetupTenantCommand = Model<"Setup.Commands.CompleteSetupTenantCommand">;
export type CompleteSetupTenantData = Model<"Setup.Commands.CompleteSetupTenantResult.CompleteSetupTenantData">;

export type SetupWorkleapUserCommand = Model<"Setup.Commands.SetupWorkleapUserCommand">;
export type SetupLegacyUserCommand = Model<"Setup.Commands.SetupLegacyUserCommand">;