import { Accordion, AccordionDetails, AccordionItem, AccordionSummary, Badge, CheckboxGroup, CheckboxGroupItem, DateRangePicker, DefaultDisabledExplainer, Heading, HStack, Text, VStack } from "@/components";
import { EthnicityLabels, GenderLabels } from "@/features/employees/types/common";
import { type FilterField, type FiltersFormProps, type InitialFilters, type ListFilterItem, type Section, isCustomField, skillsCategories } from "@/features/filter/types/common";
import { useGetSeniorityLevels } from "@/features/roles/hooks/useGetSeniorityLevels";
import { type RoleStatus } from "@/features/roles/types/common";
import * as searchApi from "@/features/search/api";
import { type SearchResultItem } from "@/features/search/types/common";
import { type SkillType } from "@/features/skill/types/common";
import * as workleapSearchApi from "@/features/workleap/api/search";
import { useMarketContentTranslation, useTranslation } from "@/hooks/useTranslation";
import { useAuth, useCurrentUser } from "@/lib/auth/AuthProvider";
import { useFeatures } from "@/lib/features/hooks/useFeatures";
import { LockIcon } from "@hopper-ui/icons";
import { type ReactNode, useState } from "react";
import { FilterSelector } from "./FilterSelector";

export const searchEmployees = async (search: string) =>
  (await searchApi.searchEmployees({ search })).data.results;

const searchDepartments = async (search: string) =>
  (await searchApi.searchDepartments({ search })).data.results;

const searchRoles = async (search: string) =>
  (await searchApi.searchRoles({ search })).data.results;

const searchRolesSkills = async (search: string) =>
  (await searchApi.searchRolesSkills({ search })).data.results;

const searchGenders = async (search: string) =>
  (await searchApi.searchGenders({ search })).data.results.map(item => ({
    ...item,
    name: GenderLabels.get(item.id) ?? item.name
  }));

const searchEthnicities = async (search: string) =>
  (await searchApi.searchEthnicities({ search })).data.results.map(item => ({
    ...item,
    name: EthnicityLabels.get(item.id) ?? item.name
  }));

const searchTeams = async (search: string) =>
  (await workleapSearchApi.searchTeams({ search })).data.map(item => ({
    ...item,
    name: item.displayName
  }));

const searchSegments = async (search: string) =>
  (await workleapSearchApi.searchSegments({ search })).data.map(item => ({
    ...item,
    name: item.displayName
  }));

const searchSkillsCategories = skillsCategories.map(x => ({
  id: x as string,
  name: x as string
}));

const itemKeyGetter = ({ id }: SearchResultItem) => id;

interface Props extends FiltersFormProps<InitialFilters> {
  children?: ReactNode;
}

const count = (list: ListFilterItem[]) => list.length
  ? 1
  : 0;

interface FilterSetProps extends Pick<Props, "onChange"> {
  sections: Section[];
}

interface RadioGroupOption<TValue> {
  value: TValue;
  text: string;
}

type StatusesFilter = RadioGroupOption<RoleStatus>;

const AdminLabel = ({ label }: { label: string }) => {
  const { t } = useTranslation();

  return (
    <HStack justify="between" align="center">
      <Text size="xs">{label}</Text>
      <LockIcon className="text-neutral-text-weak" size="sm" aria-label={t("common.filter.administrator")} />
    </HStack>
  );
};

const FiltersSet = ({ sections, onChange }: FilterSetProps) => {
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const { translateMarketContent } = useMarketContentTranslation();
  const itemNameGetter = ({ name }: SearchResultItem) => translateMarketContent(name);

  return ( 
    <Accordion
      selectedItems={expandedKeys}
      onChange={setExpandedKeys}
      allowMultipleExpanded
    >
      {sections.map(({ name, numberOfSelectedFilters, fields }) => (
        <AccordionItem className="overflow-hidden" key={name} itemId={name}>
          <AccordionSummary>
            <HStack justify="between" align="center">
              <Heading size="sm">{name}</Heading>
              {numberOfSelectedFilters > 0 && (
                <Badge number={numberOfSelectedFilters} />
              )}
            </HStack>
          </AccordionSummary>
          <AccordionDetails>
            <VStack>
              {fields.map(fieldDefinition => isCustomField(fieldDefinition, "content")
                ? fieldDefinition.content
                : (
                  <FilterSelector
                    key={fieldDefinition.field}
                    {...fieldDefinition}
                    autoCompleteProps={{
                      ...fieldDefinition.autoCompleteProps,
                      itemKeyGetter,
                      itemNameGetter
                    }}
                    onChange={onChange}
                  />
                ))}
            </VStack>
          </AccordionDetails>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export const FiltersForm = ({ onChange, values, children, hideDefaultFilters, hideDraftRoleStatusFilterOption }: Props) => {
  const { t } = useTranslation();
  const { data: seniorityLevels } = useGetSeniorityLevels();
  const { translateMarketContent } = useMarketContentTranslation();
  const { currentUserHasPermission } = useAuth();
  const { tenant: { isWorkleapTenant } } = useCurrentUser();
  const { isFeatureAvailableOrHasPermissionLevel } = useFeatures();
  const { getBlockedFeature, hasFeature } = useFeatures();

  const showWorkleapFilters = isWorkleapTenant && hasFeature("Features.WorkleapFilters");

  const searchSeniority = async () => seniorityLevels?.map(({ level, name }) => ({
    id: String(level),
    name: translateMarketContent(name)
  })) ?? [];

  const blockedFeature = getBlockedFeature("Features.AdvancedFilters");

  const onChangeHireDate = ([fromHireDate, toHireDate]: [Date?, Date?]) => onChange({
    fromHireDate,
    toHireDate
  });

  const onChangeBirthDay = ([birthdayFrom, birthdayTo]: [Date?, Date?]) => onChange({
    birthdayFrom,
    birthdayTo
  });

  const employeeFilterFields: FilterField[] = [{
    field: "employeeIds",
    label: t("common.filters.name"),
    autoCompleteProps: {
      fetchItemsQuery: searchEmployees,
      selectedItems: values.employeeIds
    }
  }];

  if (isFeatureAvailableOrHasPermissionLevel("Features.AdvancedFilters")) {
    employeeFilterFields.push({
      field: "managerIds",
      blockedFeature,
      label: t("common.filters.manager"),
      autoCompleteProps: {
        fetchItemsQuery: searchEmployees,
        selectedItems: values.managerIds
      }
    });
    
    employeeFilterFields.push({
      field: "departmentIds",
      blockedFeature,
      label: t("common.filters.department"),
      autoCompleteProps: {
        fetchItemsQuery: searchDepartments,
        selectedItems: values.departmentIds
      }
    });
  
    if (showWorkleapFilters && currentUserHasPermission("Permissions.Filters.Teams")) {
      employeeFilterFields.push({
        field: "teamIds",
        blockedFeature,
        label: t("common.filters.teams"),
        autoCompleteProps: {
          fetchItemsQuery: searchTeams,
          selectedItems: values.teamIds
        }
      });
    }

    if (showWorkleapFilters && currentUserHasPermission("Permissions.Filters.Segments")) {
      employeeFilterFields.push({
        field: "segmentIds",
        blockedFeature,
        label: t("common.filters.segments"),
        autoCompleteProps: {
          fetchItemsQuery: searchSegments,
          selectedItems: values.segmentIds
        }
      });
    }
  }

  const roleFilterFields: FilterField[] = [{
    field: "roleIds",
    label: t("common.filters.jobTitle"),
    autoCompleteProps: {
      fetchItemsQuery: searchRoles,
      selectedItems: values.roleIds
    }
  }];
  
  const skillFilterFields: FilterField[] = [{
    field: "skillsNames",
    label: t("common.filters.name"),
    autoCompleteProps: {
      fetchItemsQuery: searchRolesSkills,
      selectedItems: values.skillsNames
    } 
  }, {
    field: "skillsCategories",
    id: "skillsCategories",
    content: (
      <CheckboxGroup
        key="skillsCategories"
        onChange={newValues => onChange({ skillsCategories: newValues.map(value => ({ id: value, name: value })) })}
        label={t("common.filters.type")}
        defaultValue={(values["skillsCategories"] || []).map(({ id }) => id as SkillType)}
      >
        {searchSkillsCategories.map(({ id, name }) => (
          <CheckboxGroupItem key={id} value={id}>
            <Text size="xs">{name}</Text>
          </CheckboxGroupItem>
        ))}
      </CheckboxGroup>
    )
  }];

  const defaultSections: Section[] = [{
    name: t("common.filters.employees"),
    numberOfSelectedFilters: count(values.employeeIds) + count(values.managerIds) +
      count(values.departmentIds) + count(values.teamIds) + count(values.segmentIds),
    fields: employeeFilterFields
  }, {
    name: t("common.filters.roles"),
    numberOfSelectedFilters: count(values.roleIds) + count(values.seniorityLevels) + count(values.roleStatuses),
    fields: roleFilterFields
  }, {
    name: t("common.filters.skills"),
    numberOfSelectedFilters: count(values.skillsNames) + count(values.skillsCategories),
    fields: skillFilterFields
  }];

  if (isFeatureAvailableOrHasPermissionLevel("Features.AdvancedFilters")) {
    const roleStatuses: StatusesFilter[] = [{ value: "Published", text: t("roles.filters.status.published") }];
    if (!hideDraftRoleStatusFilterOption) {
      roleStatuses.push({ value: "Draft", text: t("roles.filters.status.draft") });
    }
    
    roleStatuses.push({ value: "Archived", text: t("roles.filters.status.archived") });
  
    const defaultStatuses: RoleStatus[] = (values["roleStatuses"] || [{ id: "Published", name: "Published" }]).map(({ id }) => id as RoleStatus);

    roleFilterFields.push({
      field: "seniorityLevels",
      blockedFeature,
      label: t("common.filters.seniority"),
      itemDisplay: (item: string) => (<div key={item}>{item}</div>),
      autoCompleteProps: {
        fetchItemsQuery: searchSeniority,
        selectedItems: values.seniorityLevels
      }
    });
    
    roleFilterFields.push({
      id: "roleStatuses",
      field: "roleStatuses",
      content: (
        <CheckboxGroup 
          key="roleStatuses"
          onChange={newValues => onChange({ roleStatuses: newValues.map(value => ({ id: value, name: value })) })}
          label={t("roles.filters.status.label")}
          defaultValue={defaultStatuses}
        >
          {roleStatuses.map(({ value, text }) => (
            <CheckboxGroupItem key={value} value={value}>
              <Text size="xs">{text}</Text>
            </CheckboxGroupItem>
          ))}
        </CheckboxGroup>
      )
    });
  }

  if (currentUserHasPermission("Permissions.Filters.Advanced")) {
    employeeFilterFields.push({
      id: "hireDate",
      content: (
        <DateRangePicker
          key="hireDate"
          label={<AdminLabel label={t("common.labels.hireDate")} />}
          value={[values.fromHireDate, values.toHireDate]}
          onChange={onChangeHireDate}
          inputsGap={2}
          isDisabled={!hasFeature("Features.AdvancedFilters")}
          disabledExplainer={(
            <DefaultDisabledExplainer blockedFeature={blockedFeature} />
          )}
          size="sm"
        />
      )
    });
    employeeFilterFields.push({
      id: "birthday",
      content: (
        <DateRangePicker
          key="birthday"
          label={<AdminLabel label={t("common.labels.dateOfBirth")} />}
          value={[values.birthdayFrom, values.birthdayTo]}
          onChange={onChangeBirthDay}
          inputsGap={2}
          isDisabled={!hasFeature("Features.AdvancedFilters")}
          disabledExplainer={<DefaultDisabledExplainer blockedFeature={blockedFeature} />}
          size="sm"
        />
      )
    });
    employeeFilterFields.push({
      field: "genders",
      blockedFeature,
      label: <AdminLabel label={t("common.filters.gender")} />,
      autoCompleteProps: {
        fetchItemsQuery: searchGenders,
        selectedItems: values.genders
      }
    });
    employeeFilterFields.push({
      field: "ethnicities",
      blockedFeature,
      label: <AdminLabel label={t("common.filters.ethnicity")} />,
      autoCompleteProps: {
        fetchItemsQuery: searchEthnicities,
        selectedItems: values.ethnicities
      }
    });
  }

  return (
    <VStack gap={6}>
      {children}
      {!hideDefaultFilters && <FiltersSet sections={defaultSections} onChange={onChange} />}
    </VStack>
  );
};
