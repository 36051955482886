import { type Node } from "@react-types/shared";
import { type TableState } from "react-stately";
import {
  mergeProps,
  useFocusRing,
  useTableHeaderRow,
  useTableRow,
  useTableRowGroup
} from "react-aria";
import React, { useRef } from "react";

import classNames from "classnames";

interface TableRowGroupProps {
  children: React.ReactNode;
  type: "tbody" | "thead";
  className?: string;
}

export const TableRowGroup = ({ type: Element, className, children }: TableRowGroupProps) => {
  const { rowGroupProps } = useTableRowGroup();

  return (
    <Element {...rowGroupProps} className={className}>
      {children}
    </Element>
  );
};

interface TableRowProps {
  item: Node<unknown>;
  state: TableState<unknown>;
  children: React.ReactNode;
  className?: string;
}

export const TableHeaderRow = ({ item, state, children, ...rest }: TableRowProps) => {
  const ref = useRef<HTMLTableRowElement>(null);
  const { rowProps } = useTableHeaderRow({ node: item }, state, ref);

  return (
    <tr {...rowProps} {...rest} ref={ref}>
      {children}
    </tr>
  );
};

export const TableRow = ({ item, children, state }: TableRowProps) => {
  const ref = useRef<HTMLTableRowElement>(null);
  const { rowProps } = useTableRow(
    {
      node: item
    },
    state,
    ref
  );
  const { focusProps } = useFocusRing();

  return (
    <tr
      {...mergeProps(rowProps, focusProps)}
      // Removes the selection on click of the row
      onPointerDown={() => {}}
      ref={ref}
      className={classNames({ "!bg-warning-icon-weakest": state.selectionManager.isSelected(item.key) })}
    >
      {children}
    </tr>
  );
};

