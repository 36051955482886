import classNames from "classnames";

interface Props {
  isSelected: boolean;
}

export const Step = ({ isSelected }: Props) => (
  <div 
    className={classNames("h-2 w-2 rounded-full", { 
      "bg-primary-surface-strong": isSelected,
      "bg-primary-surface-press": !isSelected 
    })}
  />
);