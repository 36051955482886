import { type SkillRatingForm } from "@/features/skill-rating/types/skillRatingForm";
import { type SkillRating } from "@/features/skills/types/Skill";
import { getSkillLevelsMetadata } from "@/features/skills/types/SkillLevels";
import { Grid } from "@workleap/orbiter-ui";
import { useWatch } from "react-hook-form";
import { SkillRatingLevelBucket } from "./SkillRatingLevelBucket";

export const SkillRatingLevelBuckets = () => {
  const skillLevels = getSkillLevelsMetadata();
  const ratings = Object.keys(skillLevels) as SkillRating[];
  const skills = useWatch<SkillRatingForm, "skills">({ name: "skills" });
  
  return (
    <Grid 
      templateColumns={{
        base: ["1fr"],
        sm: ["1fr 1fr"],
        md: ["1fr 1fr 1fr 1fr"]
      }}
      templateRows={{
        base: ["1fr 1fr 1fr 1fr"],
        sm: ["1fr 1fr"],
        md: ["1fr"]
      }}
      gap="inline-md"
      overflow="hidden"
      width="100%"
      height="100%"
      minHeight={{
        base: "auto",
        lg: "calc(100vh - 368px)"
      }}
    >
      {ratings.map(rating => (
        <SkillRatingLevelBucket
          key={rating}
          rating={rating}
          skillsInRating={skills.filter(x => x.rating === rating)}
        />
      ))}
    </Grid>
  );
};