import { useDirectoryRoutes } from "@/features/directory/hooks/useDirectoryRoutes";
import { type ZendeskSettings } from "@/features/types/zendesk";
import { useTranslation } from "@/hooks/useTranslation";
import { useCurrentUser } from "@/lib/auth/AuthProvider";
import { useConfiguration } from "@/lib/core";
import { Routes } from "@/types";
import { useEffect, useMemo, type PropsWithChildren } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useScript } from "usehooks-ts";

export const ZendeskProvider = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const { zendeskToken } = useConfiguration();
  const { fullName, email, language, isSuperAdmin, isExecutive } = useCurrentUser();
  const location = useLocation();
  const { editPersonSkills } = useDirectoryRoutes();
  const status = useScript(`https://static.zdassets.com/ekr/snippet.js?key=${zendeskToken}`, { id: "ze-snippet", shouldPreventLoad: !isSuperAdmin && !isExecutive });

  const settings: ZendeskSettings = {
    webWidget: {
      launcher: {
        label: {
          "*": t("zendesk.launcher.label")
        }
      },
      contactForm: {
        title: {
          "*": t("zendesk.contactForm.title")
        },
        selectTicketForm: {
          "*": t("zendesk.contactForm.selectTicketForm.title")
        },
        fields: [
          { id: "name", prefill: { "*": fullName } },
          { id: "email", prefill: { "*": email! } }
        ]
      }
    }
  };

  const verticalPadding = useMemo(() => {
    const currentPath = location.pathname;

    // In some pages we have a footer that overlaps with the zendesk widget, so we need to add some padding
    const isOnEditSkills = currentPath.startsWith(editPersonSkills);
    const isDuringSetup = currentPath.startsWith(Routes.Setup);
    const isDuringCareerProgressionSteps = matchPath("/grow/:id/steps/*", currentPath);
    const isInForm = currentPath.startsWith(Routes.AddTraining) || 
                     matchPath(Routes.EditTraining, currentPath) || 
                     currentPath.startsWith(Routes.SeekRoleAdd) ||
                     matchPath(Routes.SeekRoleEdit, currentPath) ||
                     currentPath.startsWith(Routes.SeekPeopleAdd) ||
                     matchPath("/seek/people/edit/:employeeId", currentPath) ||
                     matchPath("/settings/*", currentPath);

    return (isDuringSetup || isOnEditSkills || isDuringCareerProgressionSteps || isInForm) ? 80 : 0;
  }, [location.pathname, editPersonSkills]);

  useEffect(() => {
    if (status === "ready") {
      window.zE?.("webWidget", "setLocale", language);
      window.zE?.("webWidget", "updateSettings", settings);
    }
  }, [status, language, settings]);

  useEffect(() => {
    if (status === "ready") {
      window.zE?.("webWidget", "updateSettings", {
        offset: {
          vertical: `${verticalPadding}px`
        }
      });
    }
  }, [status, verticalPadding]);

  return children;
};