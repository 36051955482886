import { HStack } from "@/components/stack";
import { Step } from "./Step";
import { times } from "lodash";

interface Props {
  currentStep: number;
  numberOfSteps: number;
}

export const Steps = ({ currentStep, numberOfSteps }: Props) => (
  <HStack gap={1.5} align="center">
    {times(numberOfSteps, index => <Step key={index} isSelected={index + 1 <= currentStep} />)}
  </HStack>
);