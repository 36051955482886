import { useGetHrisSettingsQuery } from "@/features/hris/hooks/useGetHrisSettings";
import { UserDropdownActionKeys, type UserDto } from "@/features/users/types/common";
import { useAuth } from "@/lib/auth/AuthProvider";

export const useUserActions = () => {
  const { isCurrentUser } = useAuth();
  const { data: hrisSettings } = useGetHrisSettingsQuery();
  const { currentUserHasPermission } = useAuth();

  const isHrisConnected = !!hrisSettings?.isConnected;
  
  const isActivable = ({ userId, status, creationSource }: UserDto) =>
    userId && status === "Deactivated" && (creationSource?.sourceName !== "MergeSync" || !isHrisConnected);

  const isDeactivated = ({ userId, status }: UserDto) => userId && status === "Deactivated";
  
  const isDeactivable = ({ userId, status, creationSource }: UserDto) =>
    userId && status !== "Deactivated" && (creationSource?.sourceName !== "MergeSync" || !isHrisConnected);

  const isInvitable = ({ email, status }: UserDto) => email && status === "Created";

  const isReinvitable = ({ email, status }: UserDto) => email && status === "Invited";

  const isDeletable = ({ creationSource }: UserDto) => (creationSource?.sourceName !== "MergeSync" || !isHrisConnected);

  const buildUserActions = (user: UserDto) => {
    const { creationSource } = user;

    return creationSource?.sourceName === "MergeSync" && isHrisConnected
      ? buildSyncedUserActions(user)
      : buildDefaultUserActions(user);
  };

  const buildSyncedUserActions = (user: UserDto) => {
    const items: UserDropdownActionKeys[] = [];

    if (currentUserHasPermission("Permissions.People.Edit")) {
      items.push(UserDropdownActionKeys.EDIT_USER);
    }

    if (isInvitable(user)) {
      items.push(UserDropdownActionKeys.SEND_INVITE);
    }

    if (isReinvitable(user)) {
      items.push(UserDropdownActionKeys.SEND_INVITE);
    }

    return items;
  };

  const buildDefaultUserActions = (user: UserDto) => {
    const { employeeId } = user;

    if (isCurrentUser(employeeId)) { return [UserDropdownActionKeys.EDIT_USER]; }
    if (isDeactivated(user)) { return [UserDropdownActionKeys.ACTIVATE_USER, UserDropdownActionKeys.DELETE]; }
    
    const items: UserDropdownActionKeys[] = [UserDropdownActionKeys.EDIT_USER];

    if (isDeactivable(user)) {
      items.push(UserDropdownActionKeys.DEACTIVATED_USER);
    }

    if (isInvitable(user)) {      
      items.push(UserDropdownActionKeys.SEND_INVITE);
    }

    if (isReinvitable(user)) {
      items.push(UserDropdownActionKeys.SEND_INVITE);
    }

    if (isDeletable(user)) {
      items.push(UserDropdownActionKeys.DELETE);
    }

    return items;
  };

  return {
    isActivable,
    isInvitable,
    isReinvitable,
    isDeletable,
    isDeactivable,
    buildUserActions
  };
};
