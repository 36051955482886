import { ConditionalLink, HStack, Text, VStack } from "@/components";
import { useDirectoryRoutes } from "@/features/directory/hooks/useDirectoryRoutes";
import { getFullName } from "@/features/employees/utils";
import { UserAvatar } from "@/features/users/components/UserAvatar";
import { type UserAvatarDto, type UserStatus } from "@/features/users/types/common";
import classNames from "classnames";

interface Props {
  id?: string;
  firstName?: string;
  lastName?: string;
  avatar?: UserAvatarDto;
  departmentName?: string;
  status?: UserStatus;
}

export const EmployeeCell = ({
  firstName,
  lastName,
  avatar,
  id,
  departmentName,
  status
}: Props) => {
  const { person } = useDirectoryRoutes();

  const linkCondition = status !== "Deactivated" && !!id; 

  return (
    <ConditionalLink 
      condition={linkCondition}
      to={person(id)}
    >
      <HStack gap={2} align="center">
        <UserAvatar firstName={firstName} lastName={lastName} avatarUrl={avatar?.avatarUrl} />
        <VStack gap={0}>
          <Text 
            size="sm"
            className={classNames({
              "hover:underline": linkCondition
            })}
          >
            {getFullName(firstName, lastName)} 
          </Text>  
          {departmentName && <Text size="xs" color="neutral-text-weak">{departmentName}</Text>}
        </VStack>
      </HStack>
    </ConditionalLink>
  );
};